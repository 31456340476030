import React from 'react';
import classes from './categoryHeader.module.css';

const CategoryHeader = ({ title, info, addedClass }) => {
    const infoString = info && info.join(' ');
    const isMaterial = info && infoString.includes('Почтой');
    return (
        <>
            <h1 className={classes.categoryTitle + ' ' + addedClass}>
                {title}
            </h1>
            {info && (
                <div className={classes.infoWrap}>
                    {info.map((item) => (
                        <p className={classes.infoArr} key={item}>
                            {item}
                        </p>
                    ))}
                    {isMaterial && (
                        <p className={classes.infoArr}>
                            В&nbsp;данный момент пересылка возможна{' '}
                            <span className='noHyph fw500'>
                                только в регионы РФ.
                            </span>
                        </p>
                    )}

                    <p className={classes.infoString}>
                        {infoString + ' '}
                        {isMaterial && (
                            <>
                                В&nbsp;данный момент пересылка возможна{' '}
                                <span className='noHyph fw500'>
                                    только в регионы РФ.
                                </span>
                            </>
                        )}
                    </p>
                </div>
            )}
        </>
    );
};

export default CategoryHeader;
