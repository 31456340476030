const supporters = [
    { id: 'wjiclkqe', name: 'Игорь Кузаев', sum: 1500 },
    { id: 'ntljnnii', name: 'Андрей Юрьевич', sum: 2000 },
    { id: 'fkarxdaq', name: 'Татьяна Николаевна', sum: 772 },
    { id: 'ughajfai', name: 'Дима Ди', sum: 3000 },
    { id: 'pmfqcgng', name: 'Ксения', sum: 1500 },
    {
        id: 'eaplltsc',
        name: 'Ольга Александровна',
        sum: 1000
    },
    { id: 'xrvclvss', name: 'Евгений С.', sum: 1000 },
    {
        id: 'vtyntqac',
        name: 'Яна Станиславовна',
        sum: 100
    },
    { id: 'juxoipqa', name: 'Анна Сергеевна', sum: 300 },
    {
        id: 'kpfmwsam',
        name: 'Елена Б.',
        sum: 1000
    },
    { id: 'rmbmgnqg', name: 'Татьяна Л.', sum: 220 },
    { id: 'kghucpos', name: 'Надежда', sum: 1500 },
    { id: 'gjnjshxi', name: 'Мария Журавлева', sum: 1700 },
    { id: 'uqcgycth', name: 'Елена Николаевна', sum: 700 },
    { id: 'yogpneeb', name: 'Александр ', sum: 1500 },
    { id: 'fgoetkau', name: 'Александр Иванович', sum: 1000 },
    {
        id: 'xeabqpqa',
        name: 'Ирина Анатольевна',
        sum: 500
    },
    { id: 'fnynjjgg', name: 'Екатерина Николаевна', sum: 200 },
    { id: 'fqpnjjua', name: 'Наталья Б.', sum: 300 },
    { id: 'bxouyyby', name: 'Ни Оксана', sum: 1500 },
    { id: 'unuickeb', name: 'Пирог Татьяна', sum: 1500 },
    {
        id: 'lrdxkhmt',
        name: 'юmoney ***7534',
        sum: 1940
    },
    { id: 'gwcxeylr', name: 'Николай', sum: 3500 },
    { id: 'fyqlgubf', name: 'Михаил Михайлович', sum: 5000 },
    { id: 'qnqrceud', name: 'Шлотгауэр Л.Л.', sum: 1500 },
    { id: 'ejmqiwsr', name: 'Павел Иванов', sum: 1500 },
    { id: 'lffvwhej', name: 'Сергей Балыбердин', sum: 1500 },
    { id: 'hyweewlc', name: 'Артур Ш', sum: 500 },
    { id: 'bcvdekfl', name: 'Белый Сергей ', sum: 1500 },
    { id: 'soasxtwd', name: 'Marina Zebrika, paypal', sum: 1820 },
    { id: 'lkrvsxal', name: 'noname', sum: 1000 },
    { id: 'wpfgdxjc', name: 'noname', sum: 2412 },
    { id: 'vurcrlxc', name: 'Наталья Юрьевна Э.', sum: 1000 },
    {
        id: 'tmfchyul',
        name: 'Виталий',
        sum: 1500
    },
    { id: 'mfbqkqt7', name: 'Ольга Сергеевна Д', sum: 2000 },
    { id: 'rfbqgqtj', name: 'Кристина', sum: 3000 },
    { id: 'tgbqkqta', name: 'Евгений С.', sum: 5000 },
    { id: 'mfbqkq2u', name: 'Алексей В.', sum: 500 },
    { id: 'yfbqkqt5', name: 'noname', sum: 1500 },
    { id: 'yfehdqt5', name: 'Наталья Неудачина', sum: 1500 },
    { id: 'qstthnax', name: 'Андрей Медведев', sum: 3550 },
    { id: 'bbadsrie', name: 'Виктория Валерьевна', sum: 1200 },
    { id: 'bbxjmfie', name: 'Julia Alaverdashvili, paypal', sum: 2415 },
    { id: 'bcydgeie', name: 'noname, VTB', sum: 2000 },
    { id: 'vyakvusy', name: 'Farber Igor, paypal', sum: 5042 },
    { id: 'aqfvpbjh', name: 'Ирина Курганова', sum: 2000 },
    { id: 'yobruelp', name: 'Сергей Балыбердин', sum: 1000 },
    { id: 'bvxgcpxj', name: 'Юлия', sum: 1500 },
    { id: 'oekheris', name: 'Полина Николаевна', sum: 1000 },
    { id: 'gofukrxn', name: 'Илья', sum: 1500 },
    { id: 'bcemcdfk', name: 'Дмитрий', sum: 2000 },
    { id: 'dhustgow', name: 'Питер', sum: 1000 },
    { id: 'jjcqjgqj', name: 'noname', sum: 186 },
    {
        id: 'vrvqkauw',
        name: 'Нина Михайловна',
        sum: 1000
    },
    { id: 'forablsj', name: 'Алексей ', sum: 1500 },
    { id: 'ojyesows', name: 'Александра К.', sum: 329 },
    {
        id: 'rneacovc',
        name: 'Дмитрий К.',
        sum: 1000
    },
    { id: 'igaasmie', name: 'Даниил', sum: 2650 },
    { id: 'idfhlbxy', name: 'Юля Горячева', sum: 2000 },
    { id: 'nlxmciai', name: 'noname', sum: 5000 },
    { id: 'padrihgf', name: 'noname', sum: 744 },
    { id: 'byjxiyit', name: 'noname', sum: 186 },
    {
        id: 'donpusjt',
        name: 'noname',
        sum: 186
    },
    { id: 'msqoqdoh', name: 'noname', sum: 2000 },
    { id: 'yjdavahm', name: 'noname', sum: 500 },
    { id: 'mvyqivws', name: 'Андрей', sum: 1500 },
    { id: 'lpbycxxh', name: 'noname', sum: 186 },
    { id: 'dflxqxam', name: 'noname', sum: 186 }
];

supporters.reverse();

export const capital = supporters.reduce((acc, item) => acc + item.sum, 0);

export default supporters;
